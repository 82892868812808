/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';

export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';

// Get Currency List from Currencies Table
export const GET_CURRENCIES_START = 'GET_CURRENCIES_START';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_CURRENCIES_ERROR = 'GET_CURRENCIES_ERROR';

// Currency Rates Fetch
export const CURRENCY_RATES_FETCH_START = 'CURRENCY_RATES_FETCH_START';
export const CURRENCY_RATES_FETCH_SUCCESS = 'CURRENCY_RATES_FETCH_SUCCESS';
export const CURRENCY_RATES_FETCH_ERROR = 'CURRENCY_RATES_FETCH_ERROR';

// Select Currency from Dropdown
export const CHOSE_TO_CURRENCY_START = 'CHOSE_TO_CURRENCY_START';
export const CHOSE_TO_CURRENCY_SUCCESS = 'CHOSE_TO_CURRENCY_SUCCESS';
export const CHOSE_TO_CURRENCY_ERROR = 'CHOSE_TO_CURRENCY_ERROR';

// Logout
export const ADMIN_USER_LOGOUT_START = "ADMIN_USER_LOGOUT_START";
export const ADMIN_USER_LOGOUT_SUCCESS = "ADMIN_USER_LOGOUT_SUCCESS";
export const ADMIN_USER_LOGOUT_ERROR = "ADMIN_USER_LOGOUT_ERROR";

//Restaurant Logout
export const SHOP_USER_LOGOUT_START = "SHOP_USER_LOGOUT_START";
export const SHOP_USER_LOGOUT_SUCCESS = "SHOP_USER_LOGOUT_SUCCESS";
export const SHOP_USER_LOGOUT_ERROR = "SHOP_USER_LOGOUT_ERROR";

//Restaurant user status change
export const SHOP_USER_STATUS_CHANGE_START = "SHOP_USER_STATUS_CHANGE_START";
export const SHOP_USER_STATUS_CHANGE_SUCCESS = "SHOP_USER_STATUS_CHANGE_SUCCESS";
export const SHOP_USER_STATUS_CHANGE_ERROR = "SHOP_USER_STATUS_CHANGE_ERROR";

//Category Image Delete
export const CATEGORY_IMAGE_UPLOAD_START = "CATEGORY_IMAGE_UPLOAD_START";
export const CATEGORY_IMAGE_UPLOAD_SUCCESS = "CATEGORY_IMAGE_UPLOAD_SUCCESS";
export const CATEGORY_IMAGE_UPLOAD_ERROR = "CATEGORY_IMAGE_UPLOAD_ERROR";

//Category Marker Delete
export const CATEGORY_MARKER_UPLOAD_START = "CATEGORY_MARKER_UPLOAD_START";
export const CATEGORY_MARKER_UPLOAD_SUCCESS = "CATEGORY_MARKER_UPLOAD_SUCCESS";
export const CATEGORY_MARKER_UPLOAD_ERROR = "CATEGORY_MARKER_UPLOAD_ERROR";

//User Delete
export const USER_DELETE_START = 'USER_DELETE_START';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_ERROR = 'USER_DELETE_ERROR';

//Restaurant User Delete
export const SHOP_USER_DELETE_START = 'SHOP_USER_DELETE_START';
export const SHOP_USER_DELETE_SUCCESS = 'SHOP_USER_DELETE_SUCCESS';
export const SHOP_USER_DELETE_ERROR = 'SHOP_USER_DELETE_ERROR';

//Restaurant User Update Status
export const SHOP_USER_UPDATE_START = 'SHOP_USER_UPDATE_START';
export const SHOP_USER_UPDATE_SUCCESS = 'SHOP_USER_UPDATE_SUCCESS';
export const SHOP_USER_UPDATE_ERROR = 'SHOP_USER_UPDATE_ERROR';

//Restaurant User Update
export const SHOP_UPDATE_START = 'SHOP_UPDATE_START';
export const SHOP_UPDATE_SUCCESS = 'SHOP_UPDATE_SUCCESS';
export const SHOP_UPDATE_ERROR = 'SHOP_UPDATE_ERROR';

//Cancel Reason Delete
export const CANCEL_REASON_DELETE_START = 'CANCEL_REASON_DELETE_START';
export const CANCEL_REASON_DELETE_SUCCESS = 'CANCEL_REASON_DELETE_SUCCESS';
export const CANCEL_REASON_DELETE_ERROR = 'CANCEL_REASON_DELETE_ERROR';

//Driver/Rider profile image upload
export const PROFILE_IMAGE_UPLOAD_START = 'PROFILE_IMAGE_UPLOAD_START';
export const PROFILE_IMAGE_UPLOAD_SUCCESS = 'PROFILE_IMAGE_UPLOAD_SUCCESS';
export const PROFILE_IMAGE_UPLOAD_ERROR = 'PROFILE_IMAGE_UPLOAD_ERROR';

//Driver Licence upload
export const LICENCE_IMAGE_UPLOAD_START = 'LICENCE_IMAGE_UPLOAD_START';
export const LICENCE_IMAGE_UPLOAD_SUCCESS = 'LICENCE_IMAGE_UPLOAD_SUCCESS';
export const LICENCE_IMAGE_UPLOAD_ERROR = 'LICENCE_IMAGE_UPLOAD_ERROR';

//Driver RCbook upload
export const RCBOOK_IMAGE_UPLOAD_START = 'RCBOOK_IMAGE_UPLOAD_START';
export const RCBOOK_IMAGE_UPLOAD_SUCCESS = 'RCBOOK_IMAGE_UPLOAD_SUCCESS';
export const RCBOOK_IMAGE_UPLOAD_ERROR = 'RCBOOK_IMAGE_UPLOAD_ERROR';

//Driver Insurance upload
export const INSURANCE_IMAGE_UPLOAD_START = 'INSURANCE_IMAGE_UPLOAD_START';
export const INSURANCE_IMAGE_UPLOAD_SUCCESS = 'INSURANCE_IMAGE_UPLOAD_SUCCESS';
export const INSURANCE_IMAGE_UPLOAD_ERROR = 'INSURANCE_IMAGE_UPLOAD_ERROR';

// ADD/UPDATE Promo Code 
export const ADD_PROMOCODE_START = 'ADD_PROMOCODE_START';
export const ADD_PROMOCODE_SUCCESS = 'ADD_PROMOCODE_SUCCESS';
export const ADD_PROMOCODE_ERROR = 'ADD_PROMOCODE_ERROR';

// Delete Promo Code 
export const DELETE_PROMOCODE_START = 'DELETE_PROMOCODE_START';
export const DELETE_PROMOCODE_SUCCESS = 'DELETE_PROMOCODE_SUCCESS';
export const DELETE_PROMOCODE_ERROR = 'DELETE_PROMOCODE_ERROR';

// Enable / Disable Currencies
export const CHANGE_CURRENCY_STATUS_START = 'CHANGE_CURRENCY_STATUS_START';
export const CHANGE_CURRENCY_STATUS_SUCCESS = 'CHANGE_CURRENCY_STATUS_SUCCESS';
export const CHANGE_CURRENCY_STATUS_ERROR = 'CHANGE_CURRENCY_STATUS_ERROR';

// Set Base Currency
export const SET_BASE_CURRENCY_START = 'SET_BASE_CURRENCY_START';
export const SET_BASE_CURRENCY_SUCCESS = 'SET_BASE_CURRENCY_SUCCESS';
export const SET_BASE_CURRENCY_ERROR = 'SET_BASE_CURRENCY_ERROR';

//Site Settings SETTINGS
export const UPDATE_SITE_SETTINGS_START = 'UPDATE_SITE_SETTINGS_START';
export const UPDATE_SITE_SETTINGS_SUCCESS = 'UPDATE_SITE_SETTINGS_SUCCESS';
export const UPDATE_SITE_SETTINGS_ERROR = 'UPDATE_SITE_SETTINGS_ERROR';

//Homepage Settings upload
export const HOMEPAGE_SETTINGS_UPLOAD_START = 'HOMEPAGE_SETTINGS_UPLOAD_START';
export const HOMEPAGE_SETTINGS_UPLOAD_SUCCESS = 'HOMEPAGE_SETTINGS_UPLOAD_SUCCESS';
export const HOMEPAGE_SETTINGS_UPLOAD_ERROR = 'HOMEPAGE_SETTINGS_UPLOAD_ERROR';

//Site Settings
export const SITE_SETTINGS_START = 'SITE_SETTINGS_START';
export const SITE_SETTINGS_SUCCESS = 'SITE_SETTINGS_SUCCESS';
export const SITE_SETTINGS_ERROR = 'SITE_SETTINGS_ERROR';

// Loader Buttons
export const SET_LOADER_START = 'SET_LOADER_START';
export const SET_LOADER_COMPLETE = 'SET_LOADER_COMPLETE';

// ADD/UPDATE Promo Code 
export const ADD_CANCEL_REASON_START = 'ADD_CANCEL_REASON_START';
export const ADD_CANCEL_REASON_SUCCESS = 'ADD_CANCEL_REASON_SUCCESS';
export const ADD_CANCEL_REASON_ERROR = 'ADD_CANCEL_REASON_ERROR';

// Delete location
export const DELETE_LOCATION_START = 'DELETE_LOCATION_START';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_ERROR = 'DELETE_LOCATION_ERROR';

//UPDATE Payout status 
export const SET_PAYOUT_START = 'SET_PAYOUT_START';
export const SET_PAYOUT_SUCCESS = 'SET_PAYOUT_SUCCESS';
export const SET_PAYOUT_ERROR = 'SET_PAYOUT_ERROR';

// Update Static Page Details
export const ADMIN_UPDATE_STATIC_START = 'ADMIN_UPDATE_STATIC_START';
export const ADMIN_UPDATE_STATIC_SUCCESS = 'ADMIN_UPDATE_STATIC_SUCCESS';
export const ADMIN_UPDATE_STATIC_ERROR = 'ADMIN_UPDATE_STATIC_ERROR';

//Category Delete
export const CATEGORY_DELETE_START = 'CATEGORY_DELETE_START';
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS';
export const CATEGORY_DELETE_ERROR = 'CATEGORY_DELETE_ERROR';

//Category Update
export const CATEGORY_UPDATE_START = 'CATEGORY_UPDATE_START';
export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS';
export const CATEGORY_UPDATE_ERROR = 'CATEGORY_UPDATE_ERROR';

// Active Inactive Fare
export const UPDATE_PRICING_STATUS_START = 'UPDATE_PRICING_STATUS_START';
export const UPDATE_PRICING_STATUS_SUCCESS = 'UPDATE_PRICING_STATUS_SUCCESS';
export const UPDATE_PRICING_STATUS_ERROR = 'UPDATE_PRICING_STATUS_ERROR';

// Delete Fare
export const DELETE_PRICING_START = 'DELETE_PRICING_START';
export const DELETE_PRICING_SUCCESS = 'DELETE_PRICING_SUCCESS';
export const DELETE_PRICING_ERROR = 'DELETE_PRICING_ERROR';

// ADD UPDATE Fare
export const ADD_UPDATE_PRICING_START = 'ADD_UPDATE_PRICING_START';
export const ADD_UPDATE_PRICING_SUCCESS = 'ADD_UPDATE_PRICING_SUCCESS';
export const ADD_UPDATE_PRICING_ERROR = 'ADD_UPDATE_PRICING_ERROR';

// UPDATE Delivery Vehicle Status
export const UPDATE_DELIVERY_VEHICLE_STATUS_START = 'UPDATE_DELIVERY_VEHICLE_STATUS_START';
export const UPDATE_DELIVERY_VEHICLE_STATUS_SUCCESS = 'UPDATE_DELIVERY_VEHICLE_STATUS_SUCCESS';
export const UPDATE_DELIVERY_VEHICLE_STATUS_ERROR = 'UPDATE_DELIVERY_VEHICLE_STATUS_ERROR';

// DELETE Delivery Vehicle
export const DELETE_DELIVERY_VEHICLE_START = 'DELETE_DELIVERY_VEHICLE_START';
export const DELETE_DELIVERY_VEHICLE_SUCCESS = 'DELETE_DELIVERY_VEHICLE_SUCCESS';
export const DELETE_DELIVERY_VEHICLE_ERROR = 'DELETE_DELIVERY_VEHICLE_ERROR';

// UPDATE DELIVERY VEHICLE PHOTO
export const UPDATE_DELIVERY_VEHICLE_PHOTO_START = 'UPDATE_DELIVERY_VEHICLE_PHOTO_START';
export const UPDATE_DELIVERY_VEHICLE_PHOTO_SUCCESS = 'UPDATE_DELIVERY_VEHICLE_PHOTO_SUCCESS';
export const UPDATE_DELIVERY_VEHICLE_PHOTO_ERROR = 'UPDATE_DELIVERY_VEHICLE_PHOTO_ERROR';

// ADD UPDATE DELIVERY VEHICLE
export const ADD_UPDATE_DELIVERY_VEHICLE_START = 'ADD_UPDATE_DELIVERY_VEHICLE_START';
export const ADD_UPDATE_DELIVERY_VEHICLE_SUCCESS = 'ADD_UPDATE_DELIVERY_VEHICLE_SUCCESS';
export const ADD_UPDATE_DELIVERY_VEHICLE_ERROR = 'ADD_UPDATE_DELIVERY_VEHICLE_ERROR';

// UPDATE CATEGORY PRIVILEGE
export const UPDATE_CATEGORY_PRIVILEGE_START = 'UPDATE_CATEGORY_PRIVILEGE_START';
export const UPDATE_CATEGORY_PRIVILEGE_SUCCESS = 'UPDATE_CATEGORY_PRIVILEGE_SUCCESS';
export const UPDATE_CATEGORY_PRIVILEGE_ERROR = 'UPDATE_CATEGORY_PRIVILEGE_ERROR';

//Admin load Common setting
export const ADMIN_LOAD_COMMON_SETTINGS_START = 'ADMIN_LOAD_COMMON_SETTINGS_START';
export const ADMIN_LOAD_COMMON_SETTINGS_SUCCESS = 'ADMIN_LOAD_COMMON_SETTINGS_SUCCESS';
export const ADMIN_LOAD_COMMON_SETTINGS_ERROR = 'ADMIN_LOAD_COMMON_SETTINGS_ERROR';

//Common Setting Modal
export const COMMON_SETTING_MODAL_OPEN = 'COMMON_SETTING_MODAL_OPEN';
export const COMMON_SETTING_MODAL_CLOSE = 'COMMON_SETTING_MODAL_CLOSE';

//DELETE Content Page
export const ADMIN_DELETE_CONTENTPAGE_START = 'ADMIN_DELETE_CONTENTPAGE_START';
export const ADMIN_DELETE_CONTENTPAGE_SUCCESS = 'ADMIN_DELETE_CONTENTPAGE_SUCCESS';
export const ADMIN_DELETE_CONTENTPAGE_ERROR = 'ADMIN_DELETE_CONTENTPAGE_ERROR';

//Login Modal open and close actions
export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL';
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';

//login user
export const SHOP_USER_LOGIN_START = 'SHOP_USER_LOGIN_START';
export const SHOP_USER_LOGIN_SUCCESS = 'SHOP_USER_LOGIN_SUCCESS';
export const SHOP_USER_LOGIN_ERROR = 'SHOP_USER_LOGIN_ERROR';


//Forgot Password open and close actions
export const OPEN_FORGOT_PASSWORD_MODAL = 'OPEN_FORGOT_PASSWORD_MODAL';
export const CLOSE_FORGOT_PASSWORD_MODAL = 'CLOSE_FORGOT_PASSWORD_MODAL';

//Restaurant Signup
export const SHOP_SIGNUP_START = 'SHOP_SIGNUP_START';
export const SHOP_SIGNUP_SUCCESS = 'SHOP_SIGNUP_SUCCESS';
export const SHOP_SIGNUP_ERROR = 'SHOP_SIGNUP_ERROR';

//Restaurant OperationHours
export const SHOP_OPERATION_HOURS_START = 'SHOP_OPERATION_HOURS_START';
export const SHOP_OPERATION_HOURS_SUCCESS = 'SHOP_OPERATION_HOURS_SUCCESS';
export const SHOP_OPERATION_HOURS_ERROR = 'SHOP_OPERATION_HOURS_ERROR';
//Restaurant Signup
export const SHOP_PROFILE_UPDATE_START = 'SHOP_PROFILE_UPDATE_START';
export const SHOP_PROFILE_UPDATE_SUCCESS = 'SHOP_PROFILE_UPDATE_SUCCESS';
export const SHOP_PROFILE_UPDATE_ERROR = 'SHOP_PROFILE_UPDATE_ERROR';

//Restaurant Document
export const SHOP_DOCUMENT_UPDATE_START = 'SHOP_DOCUMENT_UPDATE_START';
export const SHOP_DOCUMENT_UPDATE_SUCCESS = 'SHOP_DOCUMENT_UPDATE_SUCCESS';
export const SHOP_DOCUMENT_UPDATE_ERROR = 'SHOP_DOCUMENT_UPDATE_ERROR';

//Send verification code 
export const SEND_VERIFICATION_CODE_START = 'SEND_VERIFICATION_CODE_START';
export const SEND_VERIFICATION_CODE_SUCCESS = 'SEND_VERIFICATION_CODE_SUCCESS';
export const SEND_VERIFICATION_CODE_ERROR = 'SEND_VERIFICATION_CODE_ERROR';
// Payout - GET
export const GET_PAYOUT_START = 'GET_PAYOUT_START';
export const GET_PAYOUT_SUCCESS = 'GET_PAYOUT_SUCCESS';
export const GET_PAYOUT_ERROR = 'GET_PAYOUT_ERROR';

// Payout - Add
export const ADD_PAYOUT_START = 'Add_PAYOUT_START';
export const ADD_PAYOUT_SUCCESS = 'ADD_PAYOUT_SUCCESS';
export const ADD_PAYOUT_ERROR = 'ADD_PAYOUT_ERROR';

// Payout History - Onchange
export const ON_CHANGE_PAYOUT_START = 'ON_CHANGE_PAYOUT_START';

// Payout - Remove
export const REMOVE_PAYOUT_START = 'REMOVE_PAYOUT_START';
export const REMOVE_PAYOUT_SUCCESS = 'REMOVE_PAYOUT_SUCCESS';
export const REMOVE_PAYOUT_ERROR = 'REMOVE_PAYOUT_ERROR';

// Payout - Add
export const SET_DEFAULT_PAYOUT_START = 'SET_DEFAULT_PAYOUT_START';
export const SET_DEFAULT_PAYOUT_SUCCESS = 'SET_DEFAULT_PAYOUT_SUCCESS';
export const SET_DEFAULT_PAYOUT_ERROR = 'SET_DEFAULT_PAYOUT_ERROR';

// Payout - Verify
export const VERIFY_PAYOUT_START = 'VERIFY_PAYOUT_START';
export const VERIFY_PAYOUT_SUCCESS = 'VERIFY_PAYOUT_SUCCESS';
export const VERIFY_PAYOUT_ERROR = 'VERIFY_PAYOUT_ERROR';

// Load User Account Data
export const SET_USER_DATA_START = 'SET_USER_DATA_START';
export const SET_USER_DATA_SUCCESS = 'SET_USER_DATA_SUCCESS';
export const SET_USER_DATA_ERROR = 'SET_USER_DATA_ERROR';

export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';


//sms Verification Modal
export const SMS_VERIFICATION_MODAL_OPEN = 'SMS_VERIFICATION_MODAL_OPEN';
export const SMS_VERIFICATION_MODAL_CLOSE = 'SMS_VERIFICATION_MODAL_CLOSE';

//Restaurant Menu Update Status
export const SHOP_MENU_UPDATE_START = 'SHOP_MENU_UPDATE_START';
export const SHOP_MENU_UPDATE_SUCCESS = 'SHOP_MENU_UPDATE_SUCCESS';
export const SHOP_MENU_UPDATE_ERROR = 'SHOP_MENU_UPDATE_ERROR';

//Partner Homepage Update Status
export const PARTNER_HOMEPAGE_UPDATE_START = 'PARTNER_HOMEPAGE_UPDATE_START';
export const PARTNER_HOMEPAGE_UPDATE_SUCCESS = 'PARTNER_HOMEPAGE_UPDATE_SUCCESS';
export const PARTNER_HOMEPAGE_UPDATE_ERROR = 'PARTNER_HOMEPAGE_UPDATE_ERROR';


//Item Modal
export const ITEM_MODAL_OPEN = 'ITEM_MODAL_OPEN';
export const ITEM_MODAL_CLOSE = 'ITEM_MODAL_CLOSE';

//SubMenu Modal
export const SUB_MENU_MODAL_OPEN = 'SUB_MENU_MODAL_OPEN';
export const SUB_MENU_MODAL_CLOSE = 'SUB_MENU_MODAL_CLOSE';

//Modal
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';

//Restaurant Item Update Status
export const SHOP_ITEM_UPDATE_START = 'SHOP_ITEM_UPDATE_START';
export const SHOP_ITEM_UPDATE_SUCCESS = 'SHOP_ITEM_UPDATE_SUCCESS';
export const SHOP_ITEM_UPDATE_ERROR = 'SHOP_ITEM_UPDATE_ERROR';

//Restaurant Signup
export const SHOP_PREPARATION_TIME_UPDATE_START = 'SHOP_PREPARATION_TIME_UPDATE_START';
export const SHOP_PREPARATION_TIME_UPDATE_SUCCESS = 'SHOP_PREPARATION_TIME_UPDATE_SUCCESS';
export const SHOP_PREPARATION_TIME_UPDATE_ERROR = 'SHOP_PREPARATION_TIME_UPDATE_ERROR';

//Failed Transaction Modal

//Item Modal
export const TRANSACTION_MODAL_OPEN = 'TRANSACTION_MODAL_OPEN';
export const TRANSACTION_MODAL_CLOSE = 'TRANSACTION_MODAL_CLOSE';

//Decline Order Modal 
export const DECLINE_REASON_MODAL_OPEN = 'DECLINE_REASON_MODAL_OPEN';
export const DECLINE_REASON_MODAL_CLOSE = 'DECLINE_REASON_MODAL_CLOSE';

//User availability
export const CHECK_USER_AVAILABLE_START = 'CHECK_USER_AVAILABLE_START';
export const CHECK_USER_AVAILABLE_SUCCESS = 'CHECK_USER_AVAILABLE_SUCCESS';
export const CHECK_USER_AVAILABLE_ERROR = 'CHECK_USER_AVAILABLE_ERROR';

// SET COMMON DATA
export const SET_COMMON_DATA = 'SET_COMMON_DATA';

// Get Admin user
export const GET_ADMIN_USER_START = 'GET_ADMIN_USER_START';
export const GET_ADMIN_USER_SUCCESS = 'GET_ADMIN_USER_SUCCESS';
export const GET_ADMIN_USER_ERROR = 'GET_ADMIN_USER_ERROR';

// Get Common Settings
export const GET_COMMON_SETTINGS_TYPE_START = 'GET_COMMON_SETTINGS_TYPE_START';
export const GET_COMMON_SETTINGS_TYPE_SUCCESS = 'GET_COMMON_SETTINGS_TYPE_SUCCESS';
export const GET_COMMON_SETTINGS_TYPE_ERROR = 'GET_COMMON_SETTINGS_TYPE_ERROR';

// HEADER MODAL
export const OPEN_HEADER_MODAL = 'OPEN_HEADER_MODAL';
export const CLOSE_HEADER_MODAL = 'CLOSE_HEADER_MODAL';

//Restaurant MODIFIER Update Status
export const SHOP_MODIFIER_UPDATE_START = 'SHOP_MODIFIER_UPDATE_START';
export const SHOP_MODIFIER_UPDATE_SUCCESS = 'SHOP_MODIFIER_UPDATE_SUCCESS';
export const SHOP_MODIFIER_UPDATE_ERROR = 'SHOP_MODIFIER_UPDATE_ERROR';


export const SHOP_MODIFIER_START = 'SHOP_MODIFIER_START';
export const SHOP_MODIFIER_SUCCESS = 'SHOP_MODIFIER_SUCCESS';
export const SHOP_MODIFIER_ERROR = 'SHOP_MODIFIER_ERROR';

export const ADDITEM_MODIFIER_START = 'ADDITEM_MODIFIER_START';
export const ADDITEM_MODIFIER_SUCCESS = 'ADDITEM_MODIFIER_SUCCESS';
export const ADDITEM_MODIFIER_ERROR = 'ADDITEM_MODIFIER_ERROR';

// ADMIN ROLES MODAL
export const OPEN_ADMIN_ROLES_MODAL = 'OPEN_ADMIN_ROLES_MODAL';
export const CLOSE_ADMIN_ROLES_MODAL = 'CLOSE_ADMIN_ROLES_MODAL';

// Create Admin Role
export const CREATE_ADMIN_ROLES_START = 'CREATE_ADMIN_ROLES_START';
export const CREATE_ADMIN_ROLES_SUCCESS = 'CREATE_ADMIN_ROLES_SUCCESS';
export const CREATE_ADMIN_ROLES_ERROR = 'CREATE_ADMIN_ROLES_ERROR';

// Delete Admin Role
export const DELETE_ADMIN_ROLES_START = 'DELETE_ADMIN_ROLES_START';
export const DELETE_ADMIN_ROLES_SUCCESS = 'DELETE_ADMIN_ROLES_SUCCESS';
export const DELETE_ADMIN_ROLES_ERROR = 'DELETE_ADMIN_ROLES_ERROR';

// Create Admin User
export const CREATE_ADMIN_USER_START = 'CREATE_ADMIN_USER_START';
export const CREATE_ADMIN_USER_SUCCESS = 'CREATE_ADMIN_USER_SUCCESS';
export const CREATE_ADMIN_USER_ERROR = 'CREATE_ADMIN_USER_ERROR';

// Delete Admin User
export const DELETE_ADMIN_USER_START = 'DELETE_ADMIN_USER_START';
export const DELETE_ADMIN_USER_SUCCESS = 'DELETE_ADMIN_USER_SUCCESS';
export const DELETE_ADMIN_USER_ERROR = 'DELETE_ADMIN_USER_ERROR';

// ADMIN ROLES MODAL
export const OPEN_ADMIN_USER_MODAL = 'OPEN_ADMIN_USER_MODAL';
export const CLOSE_ADMIN_USER_MODAL = 'CLOSE_ADMIN_USER_MODAL';

// Get Admin privileges
export const ADMIN_PRIVILEGES_START = 'ADMIN_PRIVILEGES_START';
export const ADMIN_PRIVILEGES_SUCCESS = 'ADMIN_PRIVILEGES_SUCCESS';
export const ADMIN_PRIVILEGES_ERROR = 'ADMIN_PRIVILEGES_ERROR';

//Restaurant MODIFIER Update Status
export const SHOP_MODIFIER_ITEM_UPDATE_START = 'SHOP_MODIFIER_ITEM_UPDATE_START';
export const SHOP_MODIFIER_ITEM_UPDATE_SUCCESS = 'SHOP_MODIFIER_ITEM_UPDATE_SUCCESS';
export const SHOP_MODIFIER_ITEM_UPDATE_ERROR = 'SHOP_MODIFIER_ITEM_UPDATE_ERROR';

export const SHOP_COMMON_MODAL_ACTION = 'SHOP_COMMON_MODAL_ACTION';

export const UPDATE_CASH_RECEIVED_PROCESS_START = 'UPDATE_CASH_RECEIVED_PROCESS_START';
export const UPDATE_CASH_RECEIVED_PROCESS_SUCCESS = 'UPDATE_CASH_RECEIVED_PROCESS_SUCCESS';
export const UPDATE_CASH_RECEIVED_PROCESS_ERROR = 'UPDATE_CASH_RECEIVED_PROCESS_ERROR';

export const TONE_UPLOAD_START = 'TONE_UPLOAD_START';
export const TONE_UPLOAD_SUCCESS = 'TONE_UPLOAD_SUCCESS';
export const TONE_UPLOAD_ERROR = 'TONE_UPLOAD_ERROR'; 

export const SHOP_ORDER_START = 'SHOP_ORDER_START';
export const SHOP_ORDER_SUCCESS = 'SHOP_ORDER_SUCCESS';
export const SHOP_ORDER_ERROR = 'SHOP_ORDER_ERROR';

export const UPDATE_ORDER_STATUS_START = 'UPDATE_ORDER_STATUS_START';
export const UPDATE_ORDER_STATUS_ERROR = 'UPDATE_ORDER_STATUS_ERROR';
export const UPDATE_ORDER_STATUS_SUCCESS = 'UPDATE_ORDER_STATUS_SUCCESS';

export const RESTURTANT_ORDER_HISTORY_BG_COLOR = "#5EBE00";